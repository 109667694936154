:root {
  --main-font-size: 1.4rem;
  --footer-height: 4rem;
  --header-height: 4rem;
  --header-color: #111;
  --header-text-color: #ddd;
  --paragraph-spacing: 0.75rem;
  --mustard-yellow: rgb(149, 123, 6);

  --gallery-image-height: calc(var(--header-height) * 2.5);
  --gallery-image-height: calc((100vw - 2rem - (2rem)) / 2);
}

* {
  box-sizing: border-box;
}
body {
  /* position: fixed; */
  max-width: 100vw;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif;
  background-color: #9e9eb4;
  overflow-x: hidden;
}
h4 {
  margin: 0;
}
#image-upload-frame {
  width: 100%;
  height: 100%;
  border: 0;
  border: 2px solid blue;
  flex-grow: 1;
  /* border-radius: 1rem; */
}
#window-mode-container {
  width: 100%;
  height: var(--screen-height);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 1rem; 
}
#window-mode-container > #upper-area {
  flex-grow: 1;
}
#window-mode-container > #lower-area {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#window-mode-container :not(button) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#window-mode-container button:disabled {
  background: gray;
  color: #aaa;
}
#window-mode-container input::-webkit-file-upload-button,
#window-mode-container button {
  color: white;
  width: 8rem;
  background: green;
  height: 3rem;
  border-radius: 4px;
  border: 0;
  box-shadow: 0px 3px 8px #00000088;
  cursor: pointer;
  font-family: var(--main-font);
  transition: all 250ms ease;  
}
#window-mode-container input[type='file'] {
  opacity: 0;
  border: 2px solid;
  width: 100%;
  flex-grow: 1;
}
#background-image-form {
  width: 100%;
  flex-grow: 1;
  /* outline: 3px dotted black; */
}
#background-image-form:after {
  position: absolute;
  content: 'CLICK TO CHANGE';
  text-shadow: 1px 1px 1px black;
  font-size: 1rem;
  color: white;
  bottom: 1rem;
  pointer-events: none;
  font-weight: bold;
  opacity: 0.5;
}
#window-mode-container input::-webkit-file-upload-button {
  min-height: 4rem;
}

#window-mode-container button:hover, #window-mode-container input::-webkit-file-upload-button:hover {
  background: #060;
}
#window-mode-container button {
  font-size: 1.5rem;
  font-weight: bold;
}
#window-mode-container input, #window-mode-container select {
  font-family: inherit;
  font-size: 100%;
}
input#user-image-select {
  pointer-events: all;
  cursor: pointer;
}
input[type="color"] {
  cursor: pointer;
}
.Card#change-password-card {
  z-index: 1;
}
.small-text {
  opacity: 0.75;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
}
.theme-area > button {
  margin: 0 calc(var(--header-height) / 12);
  height: calc(var(--header-height) / 1.5)
}  
.Button:disabled {
  background: grey !important;
}
button.highlighted {
  background: green;
}
.switch-label {
  color: white;
}
.MuiSwitch-track, .MuiSlider-track {
  background: #ffffff88 !important;
}
.MuiSwitch-thumb, .MuiSlider-thumb {
  background: white !important;
}
.MuiSlider-root.Mui-disabled {
  opacity: 0.25 !important;
}
.MuiSlider-rail {
  background: #000000aa !important;
}
.MuiSlider-thumb {
  transform: scale(1.5);
}
.TextArea {
  width: max-content;
  max-width: 90%;
}
.TextArea.ready {
  background: rgba(0, 128, 0, 0.129);
}
.TextArea.error #password-field,
.TextArea.error #name-field {
  color: red !important;
}
.TextArea.repeat-password.error::after {
  content: "DOESN'T MATCH";
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: max-content;
  height: 1rem;
  font-size: 0.8rem;
  color: red;
  font-weight: bold;
  z-index: 2;
}
.Card header {
  font-weight: bold;
  font-size: 1.25rem;
  padding-bottom: calc(var(--header-height) / 9);
}
.Card#login-card {
  position: fixed;
  /* padding: calc(var(--footer-height) / 6); */
  opacity: 0;
  transform: translate(-50%, 0);
  top: calc(var(--header-height) / 2);
  left: 50%;
  width: auto;
  min-width: calc(var(--header-height) * 4);
  z-index: 1;
  overflow: visible;
}
.Card#login-card #login-form .TextArea {
  width: 90%;
}
.Card#login-card #login-form {
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  /* justify-content: space-between; */
  padding: calc(var(--header-height) / 6);
}
.Card#login-card #login-form .button-row {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  padding: inherit;
}
.Card#login-card #login-form > button {
  margin: calc(var(--header-height) / 3);
  height: calc(var(--header-height) / 1.5);
  font-size: calc(var(--header-height) / 4);
}
.MuiButton-root.guest-button {
  position: absolute;
  opacity: 1;
  bottom: calc(var(--header-height) / -2);
  transform: translate(-50%, 100%);
  font-size: calc(var(--header-height) / 4);
  height: calc(var(--header-height));
  background-color: rgb(43, 94, 184);
}
button.MuiButton-root.guest-button:hover {
  background: rgb(53, 85, 141);
}
button.MuiButton-root.guest-button:active {
  background: rgb(61, 126, 173);
}
.Card.bouncing {
  opacity: 1 !important;
  display: initial;
  animation-name: bounce-land;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-play-state: running;
  animation-timing-function: ease;
}
.MuiSnackbarContent-message {
  flex-grow: 1;
  font-size: 1.25rem;
}
.MuiSnackbarContent-root {
  background: rgb(0, 80, 0) !important;
}
.MuiButton-label {
  pointer-events: none;
}
p {
  font-size: 1rem !important;
  margin: var(--paragraph-spacing);
}
:not([contenteditable='true']) {
  user-select: none;
}
[contenteditable='true']:not(.multi-paragraph) {
  white-space: nowrap;
  word-wrap: none;
  display: inline;
  max-height: min-content;
}
[contenteditable='true']:not(.multi-paragraph) * {
  display: inline;
  white-space: nowrap;
}
[contenteditable='true']:not(.multi-paragraph) br {
  display: none;
}
[contenteditable='true'].boxed-note {
  /* word-wrap: unset !important; */
  white-space: unset !important;
  /* display: flex; */
}
[contenteditable='true']:hover {
  /* outline: 1px solid #00000044; */
}
[contenteditable='true']:empty::after {
  content: '(enter something here)';
  opacity: 0.6;
}
.boxed-note {
  font-size: 0.9rem;
  margin-top: 7vh !important;
  /* line-height: 10% !important; */
}
.question {
  word-wrap: unset !important;
  white-space: unset !important;
  font-weight: bold;
  display: flex;
  /* padding: var(--paragraph-spacing); */
  align-items: center;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
}
.arranging .faqs-set.selected {
  border-color: green;
  transform: scale(1.1);
}
.arranging .faq-row {
  width: 100%;
  background: green;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.arranging .faq-row .Button {
  margin: unset;
  padding: unset;
  margin: 0 0.2rem;
  width: unset;
  min-width: unset;
  width: calc(var(--header-height) / 1.5) !important;
}
.arranging .faq-row > span:last-of-type {
  flex-grow: 1;
  display: flex;
  width: auto;
}
.arranging .faqs-set {
  align-self: center;
  height: min-content;
  min-height: var(--header-height);
  background: #00000011;
  border-color: #33333344;
  cursor: pointer;
  user-select: none;
  margin: 1vh;
  margin-left: 0;
  padding: 1vh;
  width: unset;
  max-width: unset;
  width: 100%;
}
.arranging .faqs-set > * {
  pointer-events: none;
}
.faqs-set {
  border-radius: calc(var(--header-height) / 16);
  margin-top: 0.5vh;
  padding-top: 0.5vh;
  margin-bottom: 0.5vh;
  padding-bottom: 0.5vh;
  border: 3px solid transparent;
  transition: all 300ms ease;
}
.radio-area {
  width: 100%;
  display: flex;  
}
.radio-choice {
  height: calc(var(--footer-height));
  margin: 0.5vw;
  flex-grow: 1;
  cursor: pointer;
  border: 0.3rem solid #ccc !important;
  border-radius: 0.1rem;
}
.radio-choice.black {
  background: #101010;
}
.radio-choice.white {
  background: #fffffff3;
}
.radio-choice.selected {
  border-color: green;
}

#quote-banner {
  background: #00000099;
  color: white;
  padding: 0.2rem;
  font-size: calc(var(--header-height) / 4);
  /* text-align: center; */
  text-shadow: -1px -1px 0 #00000088, 1px -1px 0 #00000088, -1px 1px 0 #00000088, 1px 1px 0 #00000088;
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd {
  background-color: transparent;
}

.floating-label-box {
  border: 0.05rem solid #555;
  border-radius: inherit;
  padding: 0.5rem;
  padding-top: 0.75rem;
  /* margin: 0.5rem; */
  width: 80%;
  color: white;
  text-shadow: -1px -1px 0 #00000088, 1px -1px 0 #00000088, -1px 1px 0 #00000088, 1px 1px 0 #00000088;
  font-weight: bold;
  position: relative;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  border-radius: 4px;
}

.floating-label-box:after {
  position: absolute;
  top: -0.65rem;
  left: 0.25rem;
  padding: 0 0.25rem;
  font-size: 1rem;
  background: #777;
}

.floating-label-box > input[type=color],
.floating-label-box > div
{
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 1;
 
  border-width: 0;
  margin: 0;
  padding: 0.1rem;
  margin-top: 0.25rem;
}

@keyframes bounce-land {
  0% {
    opacity: 0;
    transform: translate(-50%, 0) scale(1.05);
  }
  50% {
    transform: translate(-50%, 0) scale(0.99);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
}
@keyframes flash-button {
  0% {
    background: #00550066;
  }
  100% {
    background: #555500;
  }
}
@keyframes bounce-button {
  50% {
    transform: scale(0.98);
    background: rgb(14, 149, 14);
  }
}

@media screen and (orientation: landscape) {
  :root {
    --gallery-image-height: calc((100vw - 4rem - (7rem)) / 5);
  }
  .Card#change-password-card {
    width: auto;
  }
  .Card#login-card {
    position: fixed;
    /* padding: calc(var(--footer-height) / 6); */
    opacity: 0;
    transform: translate(-100%, 0);
    top: calc(var(--header-height) * 1.5);
    left: calc(50%);
    width: auto;
    min-width: calc(var(--header-height) * 4);
    z-index: 1;
    overflow: visible;
  }
  .MuiButton-root.guest-button {
    transform: translate(100%, -50%);
    top: 50%;
    right: -25%;
  }
}
